import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const ALL_ROUTER = [
    {
        path: '/',
        component: () => import('../pages/new/layout'),
        hidden: true
    },
    {
        path: '/dashboard',
        component: () => import('../pages/dashboard/layout/layout.vue'),
        hidden: true,
        children: [
            {
                path: '/dashboard/posts',
                component: () => import('../pages/dashboard/views/posts/index.vue'),
                meta: {
                    title: 'Quản lí bài viết | Note Pro'
                }
            },
            {
                path: '/dashboard/posts/:_id',
                component: () => import('../pages/dashboard/views/posts/index.vue'),
                meta: {
                    title: 'Quản lí bài viết | Note Pro'
                }
            }
        ]
    },
    {
        path: '/new',
        component: () => import('../pages/new/layout'),
        meta: {
            title: 'Bảng Tin | Note Pro'
        },
        hidden: true
    },
    {
        path: '/post/:_id',
        component: () => import('../pages/new/views/contentDetail'),
        meta: {
            title: 'Note Pro'
        },
        hidden: true
    },
    // {
    //     path: '*',
    //     component: () => import('../pages/notfound/layout/index.vue'),
    //     hidden: true,
    // },
];
var router = new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({
        y: 0,
    }),
    routes: ALL_ROUTER,
});

export default router;
