import STORE from './index'
export default async function () {
    return await new Promise(resolve => {
        Promise.all([
            import('../pages/dashboard/views/posts/_store'),
            import('../pages/new/layout/_store'),
            import('../pages/new/views/contentDetail/_store'),
            import('../langs/_store'),
        ]).then(data => {
            data.forEach(store => {
                STORE.registerModule(store.default.KEY_NAME, store.default)
            })
            resolve()
        });
    })
}
