import Vue from 'vue'
import App from './App.vue'

import router from './router/index'
import store from './_store/index'
import registerModule from './_store/registerModule'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/vi'
import './styles/index.scss'; // global css
// import './icon'
import axios from 'axios'

Vue.use(ElementUI, {
  size: 'medium', // set element-ui default size
  locale
});

function permission() {
  // call API to server -> check permission ->get 15 posts, category,...
}

registerModule().then(() => {
  Vue.config.productionTip = false

  new Vue({
    render: h => h(App), store, router
  }).$mount('#app')

  // function global
  Vue.mixin({
    methods: {
      storeVue: function (STORE_KEY) {
        let s = {}
        if ([STORE_KEY] in store._modules.root._children) s = store._modules.root._children[STORE_KEY].context;

        return {
          dispatch: (action, dataInput) => (Object.getOwnPropertyNames(s).length) ? s.dispatch(action, dataInput) : '',
          getters: s.getters || {}
        }
      },
      dataComp: () => { },
      postJSON: (url, dataInput, callback) => {
        axios.post(url, dataInput).then(res => callback(res.data))
      },
      message: (type, message) => this.$message({ showClose: true, message, type, duration: 2000 }),
      langs(){ return this.storeVue('_LANGUAGES').getters.lang }
    },
  })


})


